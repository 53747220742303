import Vue from "vue";
import Router from "vue-router";
import NProgress from "nprogress";
import $ from "jquery";

import SubRouter from "./views/SubRouter.vue";
import { AuthService } from "./services/auth";
import { encontraTelaComPermissao } from "./helpers/permissions";

Vue.use(Router);

const BASE_TITLE = "TCS - MMI Injetoras";
const TITLE_SEPARATOR = " | ";
const authService = new AuthService();

const router = new Router({
	mode: "history",

	routes: [{
		path: "/",
		name: "home",
		meta: {
			title: "Dashboard",
			perms: ["DASHBOARD_INJETORAS"]
		},
		component: () => import("./views/Dashboard.vue")
	}, {
		path: "/sinoptico",
		name: "sinoptico",
		props: { id: null },
		meta: {
			title: "Sinóptico",
			perms: ["DASHBOARD_INJETORA_SINOPTICO"]
		},
		component: () => import("./views/Sinoptico/Sinoptico.vue")
	}, {
		path: "/gerenciamento",
		meta: {
			title: "Gerenciamento"
		},
		component: SubRouter,
		children: [{
			path: "/gerenciamento/maquinas",
			name: "maquinas",
			meta: {
				title: "Gerenciamento de Injetoras",
				perms: ["GER_S_MAQUINAS"]
			},
			component: () => import("./views/Maquinas/List.vue")
		}, {
			path: "/gerenciamento/ordensProducao",
			name: "ordensProducao",
			meta: {
				title: "Gerenciamento de Ordens de Produção",
				perms: ["GER_S_ORDENS_PRODUCAO"]
			},
			component: () => import("./views/OrdensProducao/List.vue")
		}, {
			path: "/gerenciamento/groupsUsers",
			name: "gruposUsuarios",
			meta: {
				title: "Gerenciamento de Grupos de Usuários",
				perms: ["GER_S_GRUPO_USUARIO"]
			},
			component: () => import("./views/GruposUsuarios/List.vue")
		}, {
			path: "/gerenciamento/users",
			name: "usuarios",
			meta: {
				title: "Gerenciamento de Usuários",
				perms: ["GER_S_USUARIOS"]
			},
			component: () => import("./views/Usuarios/List.vue")
		}]
	}, {
		path: "/perfilUsuario",
		name: "perfilUsuario",
		props: { id: null },
		meta: {
			title: "Perfil do Usuário",
			perms: []
		},
		component: () => import("./views/Usuarios/PerfilUsuario.vue")
	}, {
		name: "Login",
		path: "/login",
		meta: {
			title: "Login",
			perms: []
		},
		component: () => import("./views/Login.vue")
	}, {
		name: "relatorioTempoCiclo",
		path: "/relatorioTempoCiclo",
		meta: {
			title: "Tempo de Ciclo e Dosagem",
			perms: ["RLT_TEMPO_CICLO_DOSAGEM"]
		},
		component: () => import("./views/RelatorioTempoCiclo/Relatorio.vue")
	}, {
		name: "Login",
		path: "/login",
		meta: {
			title: "Login",
			perms: []
		},
		component: () => import("./views/Login.vue")
	},

	// ERROS
	{
		name: "401",
		path: "/forbidden401",
		meta: {
			title: "401 - Permissão insuficiente",
			perms: [],
			noRedirect: true
		},
		component: () => import("./views/401.vue")
	},

	{
		name: "403",
		path: "/forbidden",
		meta: {
			title: "403 - Permissão insuficiente",
			perms: [],
			noRedirect: true
		},
		component: () => import("./views/403.vue")
	},

	// 404 (precisa ser a última rota deste arquivo)
	{
		name: "404",
		path: "*",
		meta: {
			title: "404 - Página não encontrada",
			perms: [],
			noRedirect: true
		},
		component: () => import("./views/404.vue")
	}]
});

router.beforeEach(async (to, from, next) => {
	// Obtém os metadados da rota de destino
	const meta = to.meta || {};

	// Altera título da janela de acordo com a página acessada
	document.title = (to.meta.title ? to.meta.title + TITLE_SEPARATOR : "") + BASE_TITLE;

	// Traz as permissões inerentes ao usuário
	let userPermissions = [];
	if (localStorage.params)
		userPermissions = (JSON.parse(localStorage.params).grupoUsuario).split(",");

	// Controle de permissões de acesso
	const permissions = meta.perms || [];
	if (permissions.length == 0) {
		// Página pública
		next();
	} else if (!(await authService.isLoggedIn())) {
		// Não logado
		await authService.requireLogin();
	} else {
		let autorizado = false;
		for (const perm of permissions) {
			if (userPermissions.indexOf(perm) !== -1) {
				// Acesso autorizado
				autorizado = true;
				break;
			}
		}

		if (autorizado)
			return next();

		// A ideia é procurar uma página que o usuário possua permissão de acesso.
		// Caso não possua nenhuma permissão, ele será redirecionado
		// para uma página padrão (403).
		const result = encontraTelaComPermissao(userPermissions, router.options.routes);
		router.push({ name: result }).catch(e => {
			if (e.type != 8) console.log(e);
		});
	}
});

// Tooltips
router.afterEach(() => {
	$(".tooltip[role=tooltip]").tooltip("hide");
	setTimeout(() => {
		const $tooltips = $('[data-toggle="tooltip"]');
		if (window.innerWidth <= 790)
			$tooltips.tooltip("disable");
		else
			$tooltips.tooltip("enable");
	});
});

// Loading
NProgress.configure({ trickleRate: 0.1, trickleSpeed: 800, showSpinner: false });

router.beforeEach((to, from, next) => {
	NProgress.start();
	next();
});

router.afterEach(() => {
	NProgress.done();
});

export default router;
